<ng-container *transloco="let t">
  <form class="h-100 d-flex flex-column p-3" [formGroup]="ngForm">
    <div *ngIf="!dashboardUiChanges" class="mb-2">
      <h5>{{ t('reportMaintenanceKey') }}</h5>
      <p class="text-muted mb-0">
        {{
          t('youCanFindAdditionalInformationAboutTaskOrBookingKeyParam', {
            taskOrBooking: 'task'
          })
        }}
      </p>
    </div>
    <ng-container *ngIf="dashboardUiChanges && wol">
      <div class="row no-gutters flex-nowrap pb-1">
        <div
          id="reportTitle"
          class="col-6"
          [matTooltip]="(wol.description | titlecase) || ''">
          <span>{{ wol.description | titlecase }}</span>
        </div>
        <div class="col-6 m-0 p-0 text-right">
          <span class="mr-1">{{ wol ? wol.workOrderNo : '' }} |</span>
          <span>{{ wol ? wol.equipmentId : '' }}</span>
        </div>
      </div>
    </ng-container>
    <div class="flex-fill overflow-hidden">
      <div class="row h-100">
        <div class="col d-flex flex-column h-100 overflow-hidden pr-0">
          <div
            *ngIf="formSetting.basic_information.enabled"
            class="form-section">
            <!-- <h6>{{ t('statusKey') }}</h6> -->
            <div class="row">
              <div
                class="col-sm-6 mb-3"
                *ngIf="
                  formSetting.basic_information.status &&
                  !wol?.managedByTagProject
                ">
                <div
                  style="
                    background-color: var(--bkg-section);
                    display: grid;
                    height: 3.7rem;
                  ">
                  <div class="row w-100 m-auto flex-nowrap">
                    <div class="col text-right">
                      {{ t('statusInProgressKey') }}
                    </div>
                    <mat-slide-toggle
                      formControlName="finishedWorkOrderLine"
                      data-cy="cyFinished"></mat-slide-toggle>
                    <div class="col">{{ t('finishedKey') }}</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 mb-3">
                <div
                  style="
                    background-color: var(--bkg-section);
                    display: grid;
                    height: 3.7rem;
                  ">
                  <div class="row w-100 m-auto flex-nowrap">
                    <div class="col text-right">{{ t('standardKey') }}</div>
                    <mat-slide-toggle
                      [(ngModel)]="isExtendedFeedback"
                      [ngModelOptions]="{ standalone: true }"
                      data-cy="cyExtended">
                    </mat-slide-toggle>
                    <div class="col">{{ t('extendedKey') }}</div>
                  </div>
                </div>
              </div>
              <div
                class="col-sm-6 mb-3"
                *ngIf="
                  formSetting.basic_information.status &&
                  nonReportedLinesMessage
                ">
                <p class="text-muted small">
                  {{ nonReportedLinesMessage }}
                </p>
              </div>
              <div
                class="col-sm-12"
                *ngIf="formSetting.basic_information.description">
                <ng-container *ngIf="isExtendedFeedback; else extendedFeedback">
                  <verosoft-design-html-editor
                    [formControl]="
                      ngForm.controls['extFeedbackDescription']
                    "></verosoft-design-html-editor>
                </ng-container>
                <ng-template #extendedFeedback>
                  <mat-form-field appearance="fill">
                    <mat-label>{{ t('maintenanceReportKey') }}</mat-label>
                    <textarea
                      matInput
                      [rows]="2"
                      formControlName="feedbackDescription"
                      required
                      [maxLength]="250"
                      data-cy="cyDescription"></textarea>
                  </mat-form-field>
                </ng-template>
              </div>

              <div
                class="col-sm-6"
                *ngIf="!isTa && formSetting.basic_information.duration">
                <mat-form-field appearance="fill">
                  <mat-label>{{ t('durationKey') }}</mat-label>
                  <input
                    matInput
                    type="number"
                    pattern="^\d+(\.\d{0,2})?$"
                    formControlName="actual_Time"
                    #actualTime
                    data-cy="cyDuration" />
                  <button
                    matSuffix
                    mat-icon-button
                    (click)="editActualTime(1)"
                    [disabled]="isFinishedStatus">
                    <mat-icon>add</mat-icon>
                  </button>
                  <button
                    matSuffix
                    mat-icon-button
                    (click)="editActualTime(-1)"
                    [disabled]="isFinishedStatus">
                    <mat-icon>remove</mat-icon>
                  </button>
                </mat-form-field>
              </div>

              <mat-form-field
                appearance="fill"
                class="col-sm-6"
                *ngIf="formSetting.basic_information.failure_Code">
                <mat-label>{{ t('failureCodeKey') }}</mat-label>
                <input matInput formControlName="failure_Code" />
                <button
                  matSuffix
                  type="button"
                  mat-icon-button
                  (click)="getFailureCodes()">
                  <mat-icon>launch</mat-icon>
                </button>
              </mat-form-field>

              <verosoft-design-button-input
                class="col-sm-6"
                *ngIf="
                  formSetting.basic_information.technician_Code &&
                  !wol?.finished &&
                  !wol?.managedByTagProject
                "
                [label]="t('technicianCodeKey')"
                [optionsAsync]="formService.getTechnicians()"
                [formControl]="ngForm.controls['technician_Code']"
                optionId="no"></verosoft-design-button-input>
              <mat-form-field
                appearance="fill"
                class="col-sm-6"
                *ngIf="formSetting.basic_information.feedback_Type">
                <mat-label>{{ t('feedbackTypeKey') }}</mat-label>
                <mat-select
                  formControlName="feedback_Type"
                  [(value)]="feedbackType">
                  <mat-option value="General">{{ t('generalKey') }}</mat-option>
                  <mat-option value="As Found">{{
                    t('asFoundKey')
                  }}</mat-option>
                  <mat-option value="Repairs">{{ t('repairsKey') }}</mat-option>
                  <mat-option value="As Left">{{ t('asLeftKey') }}</mat-option>
                  <mat-option value="Recommendations">{{
                    t('recommendationsKey')
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <ng-container
              *ngIf="
                formSetting.meter_Reading.enabled &&
                (isResultsInputRequired || isValidationRequired)
              ">
              <mat-divider class="mb-3"></mat-divider>
              <h6>{{ t('testResultsKey') }}</h6>
              <div class="d-flex mt-3">
                <div class="row">
                  <mat-form-field class="col-sm-4" appearance="fill">
                    <mat-label>{{ t('resultTypeKey') }}</mat-label>
                    <input
                      matInput
                      placeholder="label"
                      [disabled]="true"
                      [value]="wol?.resultType" />
                  </mat-form-field>

                  <app-vsd-date-input
                    [includeTime]="true"
                    class="col-sm-4"
                    formControlName="result_Datetime"
                    [label]="t('resultDateKey')"></app-vsd-date-input>

                  <div class="col-sm-4">
                    <ng-container
                      *ngIf="wol?.resultType"
                      [ngSwitch]="wol?.resultType">
                      <ng-container *ngSwitchCase="'Numeric'">
                        <mat-form-field appearance="fill">
                          <mat-label>{{ t('valueKey') }}</mat-label>
                          <input
                            matInput
                            formControlName="meterValue"
                            type="number"
                            data-cy="cyValue" />
                          <p matSuffix>{{ wol?.unitOfMeasure }}</p>
                        </mat-form-field>
                      </ng-container>
                      <ng-container *ngSwitchCase="'Meter Reading'">
                        <mat-form-field appearance="fill">
                          <mat-label>{{ t('valueKey') }}</mat-label>
                          <input
                            matInput
                            formControlName="meterValue"
                            type="number"
                            data-cy="cyValue" />
                          <p matSuffix>{{ wol?.unitOfMeasure }}</p>
                        </mat-form-field>
                      </ng-container>
                      <ng-container *ngSwitchCase="'Binary'">
                        <label class="w-100">{{ t('valueKey') }}</label>
                        <mat-radio-group aria-label="Select an option">
                          <mat-radio-button
                            class="mr-3"
                            [value]="wol?.binaryPositiveValue"
                            (change)="handleBinaryResultValueChanged($event)"
                            [checked]="
                              wol?.resultsValue === wol?.binaryPositiveValue
                            "
                            >{{
                              wol?.binaryPositiveValue || t('naKey')
                            }}</mat-radio-button
                          >
                          <mat-radio-button
                            [value]="wol?.binaryNegativeValue"
                            (change)="handleBinaryResultValueChanged($event)"
                            [checked]="
                              wol?.resultsValue === wol?.binaryNegativeValue
                            "
                            >{{
                              wol?.binaryNegativeValue || t('naKey')
                            }}</mat-radio-button
                          >
                        </mat-radio-group>
                      </ng-container>
                      <ng-container *ngSwitchCase="'Lookup Table'">
                        <mat-form-field appearance="fill">
                          <mat-label>{{ t('valueKey') }}</mat-label>
                          <mat-select 
                            formControlName="meterValue"
                            [id]="'select-' + wol?.lineNo"
                            data-cy="cyLookupValue">
                            <mat-option *ngFor="let value of lookupValues" [value]="value">
                              {{ value }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        <mat-form-field appearance="fill">
                          <mat-label>{{ t('valueKey') }}</mat-label>
                          <input
                            matInput
                            formControlName="meterValue"
                            data-cy="cyValue" />
                          <mat-error *ngIf="meterValueControl?.invalid">{{
                            getMeterValueErrorMessage()
                          }}</mat-error>
                          <p matSuffix>{{ wol?.unitOfMeasure }}</p>
                        </mat-form-field>
                      </ng-container>
                    </ng-container>
                  </div>
                  <mat-form-field class="col-sm-4" appearance="fill">
                    <mat-label>{{ t('currentReadingKey') }}</mat-label>
                    <input
                      matInput
                      placeholder="label"
                      [disabled]="true"
                      [value]="wol?.currentReading" />
                  </mat-form-field>
                  <mat-form-field class="col-sm-4" appearance="fill">
                    <mat-label>{{ t('resultTestDescriptionKey') }}</mat-label>
                    <input
                      matInput
                      placeholder="label"
                      [disabled]="true"
                      [value]="wol?.resultTestDescription" />
                  </mat-form-field>
                  <mat-form-field class="col-sm-4" appearance="fill">
                    <mat-label>{{ t('minKey') }}</mat-label>
                    <input
                      matInput
                      placeholder="label"
                      [disabled]="true"
                      [value]="wp?.numericAcceptableLow" />
                  </mat-form-field>
                  <mat-form-field class="col-sm-4" appearance="fill">
                    <mat-label>{{ t('maxKey') }}</mat-label>
                    <input
                      matInput
                      placeholder="label"
                      [disabled]="true"
                      [value]="wp?.numericAcceptableHigh" />
                  </mat-form-field>
                </div>
              </div>
            </ng-container>
          </div>

          <div
            class="form-section tasks-container mt-3"
            *ngIf="formSetting.usage_of_this_task.enabled">
            <app-form-usage-of-task
              [(requirements)]="requirements"
              [selectedTask]="selectedTask"
              [defaultValues]="{ defaultFacility: defaultFacility }"
              [equipmentId]="wol?.equipmentId"
              [disabled]="!wol || isFinishedStatus"
              [settings]="formSetting"></app-form-usage-of-task>
          </div>
        </div>
        <div
          class="h-100 mx-3"
          *ngIf="
            formSetting.additional_Information.enabled ||
            formSetting.attachment.enabled
          ">
          <div class="form-section d-flex flex-column h-100">
            <div
              class="mb-3"
              style="min-height: 3rem"
              *ngIf="formSetting.additional_Information.enabled">
              <app-form-calculated-information
                [title]="t('calculatedInformationKey')"
                [rowsData]="
                  calculatedInformation
                "></app-form-calculated-information>
            </div>
            <div class="flex-fill" *ngIf="formSetting.attachment.enabled">
              <app-import-attachment
                [(importedFiles)]="attachments"
                [showPicture]="false"
                [disabled]="!wol"></app-import-attachment>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button-container">
      <button
        mat-raised-button
        class="card__main-button"
        type="submit"
        [disabled]="!ngForm.valid || !ngForm.dirty"
        (click)="displayConfirmation()"
        data-cy="cySave">
        {{ t('submitReportKey') }}
      </button>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="showOverlay">
  <app-form-report-confirmation-modal
    [(showOverlay)]="showOverlay"
    [requirements]="requirements"
    [formValues]="formValues"
    (confirmationChange)="
      confirmationResponse($event)
    "></app-form-report-confirmation-modal>
</ng-container>
