import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { GraphQLParams, LookupValue } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  lookupValues: { items: LookupValue[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class LookupValuesGQL extends Query<Response, GraphQLParams<'lookupValue'>> {
  document = gql(config.lookupValue.query);
}